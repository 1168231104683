<template>
  <!-- Add Syllabus Template Content Modal -->
  <Dialog header="Содержание силлабуса"
          :visible="modalVisible"
          :style="{width: '98%', maxWidth: '1200px'}" :modal="true" :closable="false">
    <div class="col-md-12 mt-4">
      <div v-if="syllabusContent_form.syllabusEvaluationOptionId != 6">
        <div class="mt-4 mx-5" v-for="n in 15" :key="n">
          <h5 class="text-center mb-3">Неделя {{ n }}</h5>
          <div v-if="n===2" class="mt-1">
            Согласно п. 6.2 <a href="https://back.uib.kz/common/syllabus_rules.pdf" target="_blank">Положения о
            проведении самостоятельных научных работ студентов (СНРС, ROS)</a>, лекция второй недели обучения
            должна содержать тему "Методы научных исследований"
          </div>

          <div class="row mt-2">
            <label class="col-md-4 col-form-label">Тема лекций <span class="text-danger" style="cursor: help" title="Это поле обязательно">*</span></label>
            <div class="col-md-8">
              <textarea class="form-control" placeholder="Укажите тему лекций"
                        @change="changeNewSyllabusContentData('week'+ n, 'lecture_text', $event)">
              </textarea>
            </div>
          </div>

          <div v-if="syllabusContent_form.newSyllabusContentInfos['weeks']" class="row mt-2">
            <label class="col-md-4 col-form-label">Рассматриваемые вопросы</label>
            <div class="col-md-8">
              <div class="row mb-1"
                   v-for="(question, index) in syllabusContent_form.newSyllabusContentInfos['weeks'][`week${n}`]['questions']"
                   :key="index">
                <div class="col-11">
                  <textarea class="form-control" placeholder="Укажите рассматриваемый вопрос"
                            @change="changeNewSyllabusContentData('week'+ n, 'questions', $event, index)">
                  </textarea>
                </div>
                <div class="d-flex align-items-center col-1">
                  <i class="fa fa-times" @click="deleteRowSyllabusQuestions(n, index)"></i>
                </div>
              </div>
              <button type="button" class="btn btn-primary mt-1" @click="addQuestion(n)">
                <i class="fas fa-plus-circle" aria-hidden="true"></i>&nbsp;Добавить вопрос
              </button>
            </div>
          </div>

          <div class="row mt-2">
            <label class="col-md-4 col-form-label">Задание на семинар</label>
            <div class="col-md-8">
              <textarea class="form-control" placeholder="Укажите задание на семинар"
                        @change="changeNewSyllabusContentData('week'+ n, 'seminar_task', $event)">
              </textarea>
            </div>
          </div>

          <div class="row mt-2">
            <label class="col-md-4 col-form-label">Материалы к чтению</label>
            <div class="col-md-8">
              <textarea class="form-control" placeholder="Укажите материалы к чтению"
                        @change="changeNewSyllabusContentData('week'+ n, 'material', $event)">
              </textarea>
            </div>
          </div>

          <div class="row mt-2">
            <label class="col-md-4 col-form-label">Лекционные часы</label>
            <div class="col-md-8">
              <input type="number" class="form-control" placeholder="Укажите лекционные часы"
                     @input="changeNewSyllabusContentData('week'+ n, 'lecture_hour', $event)">
            </div>
          </div>

          <div class="row mt-2">
            <label class="col-md-4 col-form-label">Семинарские часы</label>
            <div class="col-md-8">
              <input type="number" class="form-control" placeholder="Укажите семинарские часы"
                     @input="changeNewSyllabusContentData('week'+ n, 'seminar_hour', $event)">
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="mt-4" v-for="n in 10" :key="n">
          <label>Неделя {{ n }}</label>
          <div class="row">
            <div v-if="n===2" class="mt-1">
              Согласно п. 6.2 <a href="https://back.uib.kz/common/syllabus_rules.pdf" target="_blank">Положения о
              проведении самостоятельных научных работ студентов (СНРС, ROS)</a>, лекция второй недели обучения
              должна содержать тему "Методы научных исследований"
            </div>
            <textarea class="form-control" placeholder="Название темы"
                      @change="changeNewSyllabusContentData('week'+ n, n, $event)">
            </textarea>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <button type="button" class="btn btn-primary" @click="submitSyllabusContent">
        Создать
      </button>
      <button type="button" class="btn btn-secondary" @click="closeModal">
        Закрыть
      </button>
    </template>
  </Dialog>

  <Toast position="bottom-right" group="br" />
  <!-- End Add Syllabus Template Content Modal -->
</template>


<script>
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import Toast from 'primevue/toast';


export default {
  name: "AddSyllabusTemplateContentModal",
  data() {
    return {
      syllabus_id: +this.$route.query.syllabus_id || 1,
    }
  },
  components: {
    Toast
  },
  emits: ['closeModal'],
  props: {
    modalVisible: Boolean
  },
  computed: {
    ...mapState('syllabusContent', ['syllabusContent_form']),
  },

  methods: {
    ...mapActions('syllabusContent', ['POST_SYLLABUS_CONTENT_DATA', 'GET_SYLLABUS_CONTENT_DATA', 'GET_SYLLABUS_DATA_BY_ID']),
    ...mapMutations('syllabusContent', ['SET_NEW_SYLLABUS_CONTENT_DATA']),
    changeNewSyllabusContentData(key, property, e, question_index = null, val = 'value') {
      const value = e.target[val]
      console.log('changeNewSyllabusContentData', key, property, question_index)
      this.SET_NEW_SYLLABUS_CONTENT_DATA({key, property, value, question_index})
    },
    closeModal(){
      this.$emit('closeModal')
    },
    validateWeek(weekNumber) {
      let week = this.syllabusContent_form.newSyllabusContentInfos['weeks'][`week${weekNumber}`];
      try {
        // const isWeekValid = week.lecture_text?.trim() !== undefined
        //     && week.lecture_hour?.trim() !== undefined
        //     && week.seminar_hour?.trim() !== undefined
        //     && week.seminar_task?.trim() !== undefined
        //     && week.material?.trim() !== undefined;
        // const isQuestionsValid =
        //     week.questions &&
        //     week.questions.every((question) => question.text.trim() !== undefined && question.text.trim() !== '');

        // return isQuestionsValid && isWeekValid;
        return week.lecture_text?.trim() !== undefined;
      } catch (error) {
        this.$toast.add({ severity: 'error', summary: 'Добавление', detail: `${error}`, group: 'br', life: 3000 });
        return false;
      }
    },
    async submitSyllabusContent() {
      let validData = true
      for (let i = 1; i <= Object.keys(this.syllabusContent_form.newSyllabusContentInfos['weeks']).length; i++) {
        if (!this.validateWeek(i)) {
          validData = false
        }
      }
      if (validData) {
        let resPost = await this.POST_SYLLABUS_CONTENT_DATA(this.syllabus_id)
        console.log('resPost', resPost)
        if (resPost) {
          await this.GET_SYLLABUS_CONTENT_DATA(this.syllabus_id)
          await this.GET_SYLLABUS_DATA_BY_ID(this.syllabus_id)
          this.$toast.add({ severity: 'info', summary: 'Добавление', detail: 'Данные успешно добавились', group: 'br', life: 3000 });
          this.closeModal()
        }
      } else {
        this.$toast.add({ severity: 'error', summary: 'Добавление', detail: 'Не все поля заполнены! Заполните их и попробуйте снова', group: 'br', life: 3000 });
      }
    },
    deleteRowSyllabusQuestions(weeks_index, question_index) {
      this.syllabusContent_form.newSyllabusContentInfos['weeks'][`week${weeks_index}`]['questions'] =
          this.syllabusContent_form.newSyllabusContentInfos['weeks'][`week${weeks_index}`]['questions']
              .filter((_, idx) => idx !== question_index)
    },
    addQuestion(weeks_index) {
      this.syllabusContent_form.newSyllabusContentInfos['weeks'][`week${weeks_index}`]['questions'].push({
        week: weeks_index,
        text: '',
      })
    },
  },
}
</script>

<style scoped>

</style>
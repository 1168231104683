<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "SyllabusOfficeHours",
  data(){
    return{
      syllabus_id: +this.$route.query.syllabus_id || 0,
    }
  },
  props: {
    canSave: Boolean,
  },
  computed:{
    ...mapState('managerSchedule', ['scheduleIntervalTimes', 'days']),
    ...mapState('syllabusOfficeHours', ["syllabusOfficeHours"]),
  },
  methods:{
    ...mapActions('syllabusContent', ['GET_SYLLABUS_DATA_BY_ID']),
    ...mapActions('managerSchedule', ['GET_SCHEDULE_INTERVAL_TIMES', 'GET_DAYS']),
    ...mapActions('syllabusOfficeHours', ["GET_SYLLABUS_OFFICE_HOURS", "PUT_SYLLABUS_OFFICE_HOURS", "POST_SYLLABUS_OFFICE_HOURS", "DELETE_SYLLABUS_OFFICE_HOURS"]),
    ...mapMutations('syllabusOfficeHours', ["SET_NEW_OFFICE_HOURS", "ADD_ROW_OFFICE_HOURS", "DELETE_ROW_OFFICE_HOURS"]),
    addRowSyllabusOfficeHours() {
      console.log('dada 2')
      this.ADD_ROW_OFFICE_HOURS(this.syllabus_id)
    },
    async deleteRowSyllabusOfficeHour(index) {
      if (this.syllabusOfficeHours[index].id) {
        await this.DELETE_SYLLABUS_OFFICE_HOURS(this.syllabusOfficeHours[index].id);
        await this.GET_SYLLABUS_DATA_BY_ID(this.syllabus_id);
      }
      this.DELETE_ROW_OFFICE_HOURS(index)
    },

    changeNewSyllabusLiteratureData(index, property, e) {
      let value = e.target.value
      this.SET_NEW_OFFICE_HOURS({index, property, value})
    },

    submitSyllabusOfficeHours() {
      console.log(this.syllabusOfficeHours)
      const postSyllabusLiterature = this.syllabusOfficeHours.filter(v => v.id == undefined)
      if (postSyllabusLiterature) {
        this.POST_SYLLABUS_OFFICE_HOURS(postSyllabusLiterature).then(res => {
          if (res) {
            this.$message({title: 'Добавление', text: 'Данные успешно добавились'})
            this.GET_SYLLABUS_OFFICE_HOURS(this.syllabus_id).then(_ => {
              this.GET_SYLLABUS_DATA_BY_ID(this.syllabus_id);
            })
          }
        })
      }

      const putSyllabusLiterature = this.syllabusOfficeHours.filter(v => v.id != undefined)
      if (putSyllabusLiterature) {
        this.PUT_SYLLABUS_OFFICE_HOURS(putSyllabusLiterature).then(res => {
          if (res) {
            this.$message({title: 'Обновление', text: 'Данные успешно обновились'})
            this.GET_SYLLABUS_OFFICE_HOURS(this.syllabus_id)
          }
        })
      }
    }
  },
  async mounted() {
    await this.GET_SCHEDULE_INTERVAL_TIMES();
    await this.GET_DAYS();
    await this.GET_SYLLABUS_OFFICE_HOURS(this.syllabus_id);
  }
}
</script>

<template>
  <div class="container">
    <div class="syllabus-office-hours my-5">
      <h4 style="text-align: center">Офисные часы силлабуса</h4>

      <div class="form-row" v-for="(syllabusOfficeHour, index) in syllabusOfficeHours" :key="index">
        <div class="literature-remove-item__button d-flex flex-row-reverse">
          <i class="fa fa-times" @click="deleteRowSyllabusOfficeHour(index)"></i>
        </div>

        <p><strong>Офис час №{{index+1}}</strong></p>

        <div class="form-group row mt-4">
          <label class="col-md-3">День</label>
          <div class="col-md-9">
            <select class="form-control" :value="syllabusOfficeHour.day_id"
                    @change="changeNewSyllabusLiteratureData(index,'day_id', $event)">
              <option
                  v-for="(option, index) in [{id:0, name_ru: 'Выберите день'},...days]"
                  :value="option.id" :key="index"
                  :selected="syllabusOfficeHour.day_id === option.id">
                {{ option.name_ru }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row mt-4">
          <label for="author" class="col-md-3 col-form-label">Время</label>
          <div class="col-md-9">
            <select class="form-control" :value="syllabusOfficeHour.interval_id"
                    @change="changeNewSyllabusLiteratureData(index,'interval_id', $event)">
              <option
                  v-for="(option, index) in [{id: 0, time: 'Выберите время '},...scheduleIntervalTimes]"
                  :value="option.id" :key="index"
                  :selected="syllabusOfficeHour.interval_id === option.id">
                {{ option.time }}
              </option>
            </select>
          </div>
        </div>
        <hr>
      </div>
      <div class="form-group">
        <button @click="addRowSyllabusOfficeHours" type="button" class="btn btn-secondary"><i
            class="fas fa-plus-circle"></i>
          Добавить
        </button>
      </div>
      <div class="row mt-4">
        <div class="col-md-12 text-center">
          <button class="btn btn-pill text-white btn-block btn-primary" @click="submitSyllabusOfficeHours"
                  :disabled="!canSave">Сохранить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
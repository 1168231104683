<template>
  <div class="container">


    <div class="syllabus-template-content mt-4">
      <h4 class="text-center">Содержание дисциплины</h4>

      <AddSyllabusTemplateContentModal :modalVisible="modalVisible" @closeModal="closeModal"/>

      <div class="row mb-4" v-if="Object.keys(syllabusContent_form.syllabusContentInfos).length === 0">
        <div class="col-md-12">
          <button type="button" class="btn btn-primary" @click="openAddSyllabusContentModal">
            <i class="fas fa-plus-circle" aria-hidden="true"></i>&nbsp;Добавить содержание
          </button>
        </div>
      </div>
      <div v-if="syllabusContent_form.syllabusContentInfos.weeks">
        <div>
          <div class="mt-4" v-for="n in (syllabusContent_form.syllabusEvaluationOptionId != 6 ? 15 : 10)" :key="n">
            <h5 class="text-center mb-3"><strong>Неделя №{{ n }}</strong></h5>
            <div v-if="n===2" class="mt-1">
              Согласно п. 6.2 <a href="https://back.uib.kz/common/syllabus_rules.pdf" target="_blank">Положения о
              проведении самостоятельных научных работ студентов (СНРС, ROS)</a>, лекция второй недели обучения
              должна содержать тему "Методы научных исследований"
            </div>

            <div class="row mt-2">
              <label class="col-md-4 col-form-label">Тема лекции</label>
              <div class="col-md-8">
                  <textarea class="form-control"
                            :value="syllabusContent_form.syllabusContentInfos.weeks[`week${n}`].lecture_text"
                            placeholder="Введите тему лекций"
                            @change="changeNewSyllabusContentData('week'+ n, 'lecture_text', $event)">
                  </textarea>
              </div>
            </div>

            <div class="row mt-2">
              <label class="col-md-4 col-form-label">Рассматриваемые вопросы</label>
              <div class="col-md-8">
                <div class="row mb-1"
                     v-for="(question, index) in syllabusContent_form.syllabusContentInfos['weeks'][`week${n}`]['questions']"
                     :key="index">
                  <div class="col-11">
                    <textarea class="form-control"
                              :value="syllabusContent_form.syllabusContentInfos.weeks[`week${n}`]['questions'][index].text"
                              placeholder="Введите рассматриваемый вопрос"
                              @change="changeNewSyllabusContentData('week'+ n, 'questions', $event, index)">
                    </textarea>
                  </div>
                  <div class="d-flex align-items-center col-1">
                    <i class="fa fa-times" @click="deleteRowSyllabusQuestions(n, index)"></i>
                  </div>
                </div>
                <button type="button" class="btn btn-primary mt-1" @click="addQuestion(n)">
                  <i class="fas fa-plus-circle" aria-hidden="true"></i>&nbsp;Добавить вопрос
                </button>
              </div>
            </div>

            <div class="row mt-2">
              <label class="col-md-4 col-form-label">Задание на семинар</label>
              <div class="col-md-8">
                <textarea class="form-control"
                          :value="syllabusContent_form.syllabusContentInfos.weeks[`week${n}`].seminar_task"
                          placeholder="Введите задание на семинар"
                          @change="changeNewSyllabusContentData('week'+ n, 'seminar_task', $event)">
                </textarea>
              </div>
            </div>

            <div class="row mt-2">
              <label class="col-md-4 col-form-label">Материалы к чтению</label>
              <div class="col-md-8">
                <textarea class="form-control"
                          :value="syllabusContent_form.syllabusContentInfos.weeks[`week${n}`].material"
                          placeholder="Введите материалы к чтению"
                          @change="changeNewSyllabusContentData('week'+ n, 'material', $event)">
                </textarea>
              </div>
            </div>

            <div class="row mt-2">
              <label class="col-md-4 col-form-label">Лекционные часы</label>
              <div class="col-md-8">
                <input type="number" class="form-control" placeholder="Укажите лекционные часы"
                       :value="syllabusContent_form.syllabusContentInfos.weeks[`week${n}`].lecture_hour"
                       @input="changeNewSyllabusContentData('week'+ n, 'lecture_hour', $event)">
              </div>
            </div>

            <div class="row mt-2">
              <label class="col-md-4 col-form-label">Семинарские часы</label>
              <div class="col-md-8">
                <input type="number" class="form-control" placeholder="Укажите семинарские часы"
                       :value="syllabusContent_form.syllabusContentInfos.weeks[`week${n}`].seminar_hour"
                       @input="changeNewSyllabusContentData('week'+ n, 'seminar_hour', $event)">
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 mt-4 text-center" style="min-height: 60px">
          <button type="button" class="btn btn-primary" @click="updateSyllabusContent">Сохранить
          </button>
        </div>
      </div>

      <!--      <div class="row mt-4">-->
      <!--        <div class="col-md-12">-->
      <!--          <Accordion :activeIndex="0">-->
      <!--            <AccordionTab>-->
      <!--              <template #header>-->
      <!--                <i class="pi pi-calendar"></i>-->
      <!--                <span>Недели</span>-->
      <!--              </template>-->
      <!--            </AccordionTab>-->
      <!--          </Accordion>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <hr class="my-5">
    <SyllabusMainTasks
        :canSave="syllabusContent_form.syllabusData.document_status === null
        || syllabusContent_form.syllabusData.document_status == 2"/>
    <hr class="my-5">
    <SyllabusLiterature
        :canSave="syllabusContent_form.syllabusData.document_status === null
        || syllabusContent_form.syllabusData.document_status == 2"/>
    <hr class="my-5">
    <SyllabusOfficeHours style="margin-bottom: 10%;"
       :canSave="syllabusContent_form.syllabusData.document_status === null
        || syllabusContent_form.syllabusData.document_status == 2"/>
  </div>

  <div v-if="syllabusContent_form.syllabusData.message"
       class="fixed-bottom d-flex justify-content-center flex-column py-1"
       style="background: #FFFFFF; border-top: 1px solid rgba(0,0,0,.125);">
    <div v-if="syllabusContent_form.syllabusData.message.length == 0"
         class="d-flex justify-content-center align-items-center">
      <Button v-if="!syllabusContent_form.syllabusData.syllabus_url" label="Сформировать силлабус" class="m-1" @click="generateSyllabus()"/>
      <div class="d-flex" v-else>
        <div v-if="syllabusContent_form.syllabusData.document_status === null
                  || syllabusContent_form.syllabusData.document_status == 2">
          <Button label="Обновить силлабус" class="m-1" @click="generateSyllabus()"/>
          <router-link :to="`ncalayer?file_id=${syllabus_id}&category=syllabus`">
            <Button label="Подписать силлабус" class="m-1 p-button-success"/>
          </router-link>
        </div>
        <a :href="syllabusContent_form.syllabusData.syllabus_url + `&access-token=${getCookie('ACCESS_TOKEN')}`" target="_blank">
          <Button label="Посмотреть силлабус" class="m-1 p-button-secondary"/>
        </a>
      </div>
    </div>
    <div v-else class="d-flex flex-column justify-content-center align-items-center mt-2">
      <p class="m-0"><strong>Для возможности формирования силлабуса необходимо исправить следующее:</strong></p>
      <ol class="m-0">
        <li v-for="(message, index) in syllabusContent_form.syllabusData.message" :key="index">
          <p class="m-1">{{ message }}</p>
        </li>
      </ol>
    </div>
  </div>

</template>

<script>
import AddSyllabusTemplateContentModal from '@/components/syllabus/AddSyllabusTemplateContentModal.vue'
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import SyllabusLiterature from "@/components/syllabus/SyllabusLiterature.vue";
import SyllabusOfficeHours from "@/components/syllabus/SyllabusOfficeHours.vue";
import SyllabusMainTasks from "@/components/syllabus/SyllabusMainTasks.vue";
import {getCookie} from "@/utils/helpers/cookies.helpers";

export default {
  name: "SyllabusTemplateContent",
  components: {
    SyllabusMainTasks,
    SyllabusOfficeHours,
    SyllabusLiterature,
    AddSyllabusTemplateContentModal
  },
  data() {
    return {
      modalVisible: false,
      syllabus_id: +this.$route.query.syllabus_id || 1,
    }
  },
  computed: {
    ...mapState('syllabusContent', ['syllabusContent_form']),
  },

  methods: {
    getCookie,
    ...mapActions('syllabusContent', ['GET_SYLLABUS_CONTENT_DATA', 'PUT_SYLLABUS_CONTENT_DATA', 'GET_SYLLABUS_DATA_BY_ID',
      'DELETE_SYLLABUS_CONTENT_QUESTION', 'POST_SYLLABUS_CONTENT_FORM_SYLLABUS']),
    ...mapMutations('syllabusContent', ['UPDATE_SYLLABUS_CONTENT_DATA', 'UPDATE_NEW_SYLLABUS_CONTENT_DATA',
      'SET_URL_SYLLABUS_ID']),

    async generateSyllabus() {
      let postRes = await this.POST_SYLLABUS_CONTENT_FORM_SYLLABUS(this.syllabus_id)
      if (postRes) {
        this.$message({title: 'Силлабус', text: 'Силлабус успешно сформирован'})
        await this.GET_SYLLABUS_CONTENT_DATA(this.syllabus_id)
        await this.GET_SYLLABUS_DATA_BY_ID(this.syllabus_id)
      }
    },

    async deleteRowSyllabusQuestions(weeks_index, question_index) {
      if (this.syllabusContent_form.syllabusContentInfos['weeks'][`week${weeks_index}`]['questions'][question_index].id) {
        let deleteRes = await this.DELETE_SYLLABUS_CONTENT_QUESTION(this.syllabusContent_form.syllabusContentInfos['weeks'][`week${weeks_index}`]['questions'][question_index].id);
        if (deleteRes){
          await this.GET_SYLLABUS_CONTENT_DATA(this.syllabus_id)
        }
      }
      this.syllabusContent_form.syllabusContentInfos['weeks'][`week${weeks_index}`]['questions'] =
          this.syllabusContent_form.syllabusContentInfos['weeks'][`week${weeks_index}`]['questions']
              .filter((_, idx) => idx !== question_index)
    },

    addQuestion(weeks_index) {
      this.syllabusContent_form.syllabusContentInfos['weeks'][`week${weeks_index}`]['questions'].push({
        week: weeks_index,
        text: '',
      })
    },

    changeNewSyllabusContentData(key, property, e, question_index = null, val = 'value') {
      const value = e.target[val]
      console.log('changeNewSyllabusContentData', key, property, question_index)
      this.UPDATE_NEW_SYLLABUS_CONTENT_DATA({key, property, value, question_index})
    },

    async updateSyllabusContent() {

      await this.PUT_SYLLABUS_CONTENT_DATA(this.syllabus_id).then(res => {
        if (res.success == true) {
          this.$message({title: 'Обновление', text: 'Данные успешно обновились'})
          this.GET_SYLLABUS_CONTENT_DATA(this.syllabus_id)
        } else {
          let errorText = '';
          for (let err of res.errors[0]) {
            errorText += err.message + '\n'
          }
          this.$error({title: 'Обновление', text: errorText})
        }
      })
      await this.GET_SYLLABUS_DATA_BY_ID(this.syllabus_id);
    },
    closeModal() {
      this.modalVisible = false
    },
    openAddSyllabusContentModal() {
      this.SET_URL_SYLLABUS_ID(this.syllabus_id)
      this.modalVisible = true
    }
  },
  async mounted() {
    await this.GET_SYLLABUS_CONTENT_DATA(this.syllabus_id);
    await this.GET_SYLLABUS_DATA_BY_ID(this.syllabus_id);
    if (Object.values(this.syllabusContent_form.syllabusContentInfos).length === 0) {
      this.syllabusContent_form.newSyllabusContentInfos.syllabus_id = this.syllabus_id
      this.syllabusContent_form.newSyllabusContentInfos['weeks'] = {}
      if (this.syllabusContent_form.syllabusEvaluationOptionId != 6) {
        for (let i = 1; i <= 15; i++) {
          this.syllabusContent_form.newSyllabusContentInfos['weeks'][`week${i}`] = {}
          this.syllabusContent_form.newSyllabusContentInfos['weeks'][`week${i}`]['questions'] = []
        }
      } else {
        for (let i = 1; i <= 10; i++) {
          this.syllabusContent_form.newSyllabusContentInfos['weeks'][`week${i}`] = {}
          this.syllabusContent_form.newSyllabusContentInfos['weeks'][`week${i}`]['questions'] = []
        }
      }
    }
  }

}
</script>

<style scoped>

</style>
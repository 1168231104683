<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "SyllabusMainTasks",
  data(){
    return{
      syllabus_id: +this.$route.query.syllabus_id || 0,
    }
  },
  props: {
    canSave: Boolean,
  },
  computed:{
    ...mapState('syllabusMainTasks', ["syllabusMainTasks"]),
  },
  methods:{
    ...mapActions('syllabusContent', ['GET_SYLLABUS_DATA_BY_ID']),
    ...mapActions('syllabusMainTasks', ["GET_SYLLABUS_MAIN_TASKS", "PUT_SYLLABUS_MAIN_TASKS", "POST_SYLLABUS_MAIN_TASKS", "DELETE_SYLLABUS_MAIN_TASKS"]),
    ...mapMutations('syllabusMainTasks', ["SET_NEW_MAIN_TASKS", "ADD_ROW_MAIN_TASKS", "DELETE_ROW_MAIN_TASKS"]),
    addRowSyllabusOfficeHours() {
      this.ADD_ROW_MAIN_TASKS(this.syllabus_id)
    },
    async deleteRowSyllabusOfficeHour(index) {
      if (this.syllabusMainTasks[index].id) {
        await this.DELETE_SYLLABUS_MAIN_TASKS(this.syllabusMainTasks[index].id);
        await this.GET_SYLLABUS_DATA_BY_ID(this.syllabus_id);
      }
      this.DELETE_ROW_MAIN_TASKS(index);
    },

    changeNewSyllabusLiteratureData(index, property, e) {
      let value = e.target.value
      this.SET_NEW_MAIN_TASKS({index, property, value})
    },

    submitSyllabusOfficeHours() {
      const postSyllabusLiterature = this.syllabusMainTasks.filter(v => v.id == undefined)
      if (postSyllabusLiterature.length > 0) {
        this.POST_SYLLABUS_MAIN_TASKS(postSyllabusLiterature).then(res => {
          if (res) {
            this.$message({title: 'Добавление', text: 'Данные успешно добавились'})
            this.GET_SYLLABUS_MAIN_TASKS(this.syllabus_id).then(_ => {
              this.GET_SYLLABUS_DATA_BY_ID(this.syllabus_id);
            })
          }
        })
      }

      const putSyllabusLiterature = this.syllabusMainTasks.filter(v => v.id != undefined)
      if (putSyllabusLiterature.length > 0) {
        this.PUT_SYLLABUS_MAIN_TASKS(putSyllabusLiterature).then(res => {
          if (res) {
            this.$message({title: 'Обновление', text: 'Данные успешно обновились'})
            this.GET_SYLLABUS_MAIN_TASKS(this.syllabus_id)
          }
        })
      }
    }
  },
  async mounted() {
    await this.GET_SYLLABUS_MAIN_TASKS(this.syllabus_id);
  }
}
</script>

<template>
  <div class="container">
    <div class="syllabus-office-hours my-5">
      <h4 style="text-align: center">Основные задания в рамках курса</h4>

      <div class="form-row" v-for="(syllabusMainTask, index) in syllabusMainTasks" :key="index">
        <div class="literature-remove-item__button d-flex flex-row-reverse">
          <i class="fa fa-times" @click="deleteRowSyllabusOfficeHour(index)"></i>
        </div>

        <div class="mt-2" >
          <strong>Задание №{{index+1}}</strong>
        </div>

        <div class="form-group row mt-4">
          <label class="col-md-3 col-form-label">Задание</label>
          <div class="col-md-9">
            <input id="publishing_year" type="text" class="form-control" placeholder="Укажите название задания"
                   :value="syllabusMainTask.task"
                   @input="changeNewSyllabusLiteratureData(index,'task', $event)">
          </div>
        </div>

        <div class="form-group row mt-4">
          <label class="col-md-3 col-form-label">Описание</label>
          <div class="col-md-9">
            <input id="publishing_year" type="text" class="form-control" placeholder="Укажите описание задания"
                   :value="syllabusMainTask.description"
                   @input="changeNewSyllabusLiteratureData(index,'description', $event)">
          </div>
        </div>

        <div class="form-group row mt-4">
          <label class="col-md-3 col-form-label">Период сдачи</label>
          <div class="col-md-9">
            <input id="publishing_year" type="text" class="form-control" placeholder="Укажите период сдачи задания"
                   :value="syllabusMainTask.deadline"
                   @input="changeNewSyllabusLiteratureData(index,'deadline', $event)">
          </div>
        </div>

        <div class="form-group row mt-4">
          <label class="col-md-3 col-form-label">Критерии оценки</label>
          <div class="col-md-9">
            <input id="publishing_year" type="text" class="form-control" placeholder="Укажите критерии оценки"
                   :value="syllabusMainTask.criterions"
                   @input="changeNewSyllabusLiteratureData(index,'criterions', $event)">
          </div>
        </div>
        <hr>
      </div>
      <div class="form-group">
        <button @click="addRowSyllabusOfficeHours" type="button" class="btn btn-secondary"><i
            class="fas fa-plus-circle"></i>
          Добавить
        </button>
      </div>
      <div class="row mt-4">
        <div class="col-md-12 text-center">
          <button class="btn btn-pill text-white btn-block btn-primary" @click="submitSyllabusOfficeHours"
                  :disabled="!canSave">Сохранить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>